<template>
  <v-dialog
    v-model="dialogOnboarding"
    min-width="340"
    max-width="450"
    persistent
  >
    <v-card class="text-center pa-8">
      <v-img
        src="@/assets/images/bem_vindo_logo.png"
        max-width="338"
        class="mx-auto my-2"
      />
      <v-btn
        color="#311F50"
        class="text-capitalize font-weight-regular mt-6"
        dark
        block
        @click="openOnBoard()"
        >Iniciar tour</v-btn
      >
      <v-btn
        color="#311F50"
        class="text-capitalize font-weight-regular mt-2"
        dark
        block
        text
        @click="disable()"
        >Continuar sem tour</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { disableOnboarding } from "@/helpers/services/onboarding";
import { mapMutations } from "vuex";
export default {
  name: "FirstTimeOnBoarding",
  data: () => ({
    dialogOnboarding: JSON.parse(localStorage.getItem("show_onboarding")),
  }),

  methods: {
    ...mapMutations(["showOnBoarding", "tourOnBoardingRead"]),
    disable() {
      disableOnboarding();
      this.dialogOnboarding = false;
      this.tourOnBoardingRead(true);
    },
    openOnBoard() {
      this.showOnBoarding();
      this.dialogOnboarding = false;
    },
  },
};
</script>
